import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import PricePointCheckoutDetail from "../components/PricePointCheckoutDetail";
import DirectPurchaseCheckoutDetail from "../components/DirectPurchaseCheckoutDetail";
import PromocodeCheckoutDetail from "../components/PromocodeCheckoutDetail";
import Breadcrumbs from "../components/Breadcrumbs";
import PaymentSuccess from "./v2/PaymentSuccess";
import {
  getCheckout,
  loadDirectPurchaseCheckout,
  loadDirectPurchaseCheckoutV2,
  loadPricePointPurchaseCheckout,
  loadPricePointPurchaseCheckoutV2,
  getCheckoutLoadingState,
  getCheckoutLoadRetryState
} from "../redux/modules/checkout";
import { getPurchaseType, purchaseTypes } from "../redux/modules/paymentOptions";
import {
  getSessionToken,
  getSessionPrepaidMode,
  getSessionUseApiV2,
  getSessionCurrentPurchaseId,
  getSessionStoreCode,
  startNewPurchase,
  getSessionIsNewFlow
} from "../redux/modules/session";
import ReturnToStoreButton from "../components/ReturnToStoreButton";
import MakeAnotherPurchaseButton from "../components/MakeAnotherPurchaseButton";
import { withNavigation } from "./NavigationProvider";

class PaymentComplete extends React.Component {
  componentDidMount() {
    if (!this.props.sessionToken) {
      this.props.navigate("/checkout-failed");
    }

    // virtual content for promo code redemption does not require a purchase to be loaded
    if (this.props.checkout.virtualContent) {
      return;
    }

    if (this.props.checkout.loadRetried) {
      return;
    }

    // In case of prepaid checkout the results will be loaded from the redeem call.
    // This allows callback to create the price point purchase attempt in the background,
    // independent or the redeem call.
    if (this.props.checkout.loaded) {
      if (this.props.purchaseType === purchaseTypes.PRICE_POINT && isEmpty(this.props.checkout.purchasedPricePoint)) {
        return this.loadPurchase(true);
      }

      return;
    }

    const params = queryString.parse(this.props.location.search);

    if (params.mcChime) {
      this.loadMcScript();
    }

    this.loadPurchase();
  }

  loadMcScript = () => {
    var script = document.createElement("script");
    script.setAttribute("src", "https://sonicsdk.mastercard.com/assets/js/latest/js/mc-sonic.min.js");
    script.addEventListener("load", this.playMcSonic);
    document.head.appendChild(script);

    var div = document.createElement("div");
    div.setAttribute("id", "animContainer");
    document.body.appendChild(div);

    let scriptEle = document.createElement("mc-sonic");
    scriptEle.setAttribute("id", "mc-sonic");
    scriptEle.setAttribute("type", "sound-only");
    scriptEle.setAttribute("sonicCue", "checkout");
    document.getElementById("animContainer").appendChild(scriptEle);
  };

  playMcSonic = () => {
    let mc_component = document.getElementById("mc-sonic");
    if (mc_component.play) {
      mc_component.play();
    }
  };

  async loadPurchase(retry = false) {
    if (retry) {
      // sleep before retrying
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    if (this.props.purchaseType === purchaseTypes.DIRECT) {
      if (this.props.useApiV2) {
        this.props.loadDirectPurchaseCheckoutV2(this.props.currentPurchaseId, retry);
      } else {
        this.props.loadDirectPurchaseCheckout(this.props.sessionToken, retry);
      }
    } else {
      if (this.props.useApiV2) {
        this.props.loadPricePointPurchaseCheckoutV2(this.props.currentPurchaseId, retry);
      } else {
        this.props.loadPricePointPurchaseCheckout(this.props.sessionToken, retry);
      }
    }
  }

  showReturnToStoreButton = () => {
    const excludedStoreCodes = ["valorant", "lol"];
    return !excludedStoreCodes.includes(this.props.storeCode);
  };

  showMakeAnotherPurchaseButton = () => {
    const excludedStoreCodes = ["2xko"];
    return this.props.useApiV2 && !excludedStoreCodes.includes(this.props.storeCode);
  };

  startNewPurchase = () => {
    this.props.startNewPurchase();
    this.props.navigate("/");
  };

  render() {
    const { checkout, purchaseType, loading, storeCode, isNewFlow } = this.props;
    let sidebar = <div />;
    if (purchaseType === purchaseTypes.DIRECT) {
      sidebar = <DirectPurchaseCheckoutDetail {...checkout} />;
    } else if (checkout.purchasedPricePoint && !isEmpty(checkout.purchasedPricePoint)) {
      sidebar = <PricePointCheckoutDetail {...checkout} />;
    } else {
      sidebar = <PromocodeCheckoutDetail virtualContent={checkout.virtualContent} />;
    }

    return (
      <div>
        {isNewFlow ? (
          <PaymentSuccess />
        ) : (
          <>
            {!this.props.prepaidMode && <Breadcrumbs step={3} />}
            {loading ? (
              <div className="container loading">
                <div className="spinner" />
              </div>
            ) : (
              <div className="container payment-complete fade-in">
                <div className="sidebar psp">{sidebar}</div>
                <div className="main">
                  <h1>
                    <FormattedMessage id="congrats" defaultMessage="Congratulations" />
                    <span>!</span>
                  </h1>
                  {storeCode === "2xko" && checkout.purchasedPricePoint?.virtualAmount && (
                    <h2>
                      <span className="purchased-amount">{(checkout.purchasedPricePoint.virtualAmount) + (checkout.purchasedPricePoint.virtualBonusAmount || 0)}</span>
                    </h2>
                  )}
                  {checkout.playerFacingId && (
                    <div className="transaction text-primary">
                      <FormattedMessage id="transaction_id" defaultMessage="Transaction Id" />:{" "}
                      {checkout.playerFacingId}
                    </div>
                  )}
                  <div className="actions">
                    {this.showReturnToStoreButton() && (
                      <>
                        <ReturnToStoreButton buttonMessage="return_to_store" buttonMessageDefault="Return to Store" />
                        <br />
                      </>
                    )}
                    {this.showMakeAnotherPurchaseButton() && (
                      <MakeAnotherPurchaseButton onClick={this.startNewPurchase} />
                    )}
                  </div>
                  {storeCode === "2xko" && checkout.newBalance && (
                    <div className="payment-balance">
                      <FormattedMessage id={`new_balance`} defaultMessage={`New Balance`} />:
                      <span className="new-balance">{checkout.newBalance}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

PaymentComplete.propTypes = {
  checkout: PropTypes.object.isRequired,
  loadDirectPurchaseCheckout: PropTypes.func.isRequired,
  loadDirectPurchaseCheckoutV2: PropTypes.func.isRequired,
  loadPricePointPurchaseCheckout: PropTypes.func.isRequired,
  loadPricePointPurchaseCheckoutV2: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  sessionToken: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loadRetried: PropTypes.bool.isRequired,
  purchaseType: PropTypes.string,
  prepaidMode: PropTypes.bool.isRequired,
  useApiV2: PropTypes.bool.isRequired,
  startNewPurchase: PropTypes.func.isRequired,
  currentPurchaseId: PropTypes.string,
  storeCode: PropTypes.string
};

const mapStateToProps = (state) => ({
  checkout: getCheckout(state),
  sessionToken: getSessionToken(state),
  purchaseType: getPurchaseType(state),
  loading: getCheckoutLoadingState(state),
  loadRetried: getCheckoutLoadRetryState(state),
  prepaidMode: getSessionPrepaidMode(state),
  useApiV2: getSessionUseApiV2(state),
  currentPurchaseId: getSessionCurrentPurchaseId(state),
  storeCode: getSessionStoreCode(state),
  isNewFlow: getSessionIsNewFlow(state)
});

export default connect(mapStateToProps, {
  loadDirectPurchaseCheckout,
  loadDirectPurchaseCheckoutV2,
  loadPricePointPurchaseCheckout,
  loadPricePointPurchaseCheckoutV2,
  startNewPurchase
})(withNavigation(PaymentComplete));
